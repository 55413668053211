import Api from '../https/Api';

export class CurrencyConvert {
  private api: Api;
  constructor() {
    this.api = new Api();
  }

  async convert(from:string, to:string, amount:number) {
    let info:any = [];
    /*
    //await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`, 
    await fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${from}.json`,
    { method: 'GET', mode: 'cors',
      cache: 'default'
    }).then(response => response.json())
      .then(function (response) {
        info = (response[from]);
      }).catch(error => console.log(error))

    return amount * info[to] */

      if (!from || !to) {
        throw new Error("Invalid currency codes.");
    }
    try {
        const response = await fetch(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${from}.json`, {
            method: 'GET', 
            mode: 'cors', 
            cache: 'default'
        });
        if (!response.ok) {
            throw new Error(`Currency Converter API call failed with status: ${response.status}`);
        }
        const data = await response.json();
        const rate = data[from]?.[to];
        if (!rate) {
            throw new Error(`Conversion rate for ${from} to ${to} not found.`);
        }
        return amount * rate;
    } catch (error) {
      if (error instanceof Error) {
          console.error(`Currency conversion error: ${error.message}`);
      } else {
          console.error(`An unknown error occurred: ${error}`);
      }
      return amount; // Return original amount on error
  }
  
  }

  getConvertedValue(from:string, to:string, amount:number) {
    return this.convert(from, to, amount).then(value => value)
  }

  async convertDevise(from:string, to:string){
    // console.log("conversion convertDevise front")
    return this.api.getWithURL(`https://api.fastforex.io/fetch-one?from=${from.toUpperCase()}&to=${to.toUpperCase()}&api_key=378c5bcdaf-880c4acd49-r7aijm`)
    .then((response) => {
        if (response.result) return response.result;
        else return null
    }).catch((err) => {
        console.log('CurrencyConverterService', err);
        throw err
    });
  }
}
