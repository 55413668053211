import {
    AnnounceInputs,
    PropertyInputs,
} from "../../../utils/AnnounceInterfaces";
import Api from "../../../https/Api";

export class PropertyService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getLocationTypes() {
        return this.api.get("locationTypes");
    }

    async getAnnounceByIdAdm(id: string) {
        return this.api.simpleGet("announces/getadm", id);
    }

    async getListAnnounces(filter: any, page: number = 1, limit: number = 10) {
        return this.api.simpleGetWithFilter(`announces`, filter);
    }

    async getListProperties(filter: any) {
        return this.api.simpleGetWithFilter(`properties`, filter);
    }

    async getAllEquipments() {
        return this.api.get("equipments");
    }

    async getAllPropertyTypes() {
        return this.api.get("propertiesTypes");
    }

    async getPropertyTypesBySearchType(searchTypeName: string) {
        return this.api.get(`propertytypes/searchtype/${searchTypeName}`);
    }

    async getAllEquipement() {
        return this.api.get("equipments");
    }
    async getAllCategory() {
        return this.api.get("categories");
    }

    async getPropertyById(id: string) {
        return this.api.simpleGet("properties/get", id);
    }

    async getListPropertiesPerPage(
        filter: any,
        page: number = 1,
        limit: number = 10
    ) {
        return this.api.simpleGetWithFilter(
            `properties/${page}/${limit}`,
            filter
        );
    }

    async getCommissionFeeByCountryAndCityId(
        countryName: string,
        cityName: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city",
            countryName,
            cityName
        );
    }

    async getHostFeeByCountryAndCityId(countryName: string, cityName: string) {
        return this.api.simpleGetWithTwoParams(
            "settings/hosts-fees-country-city",
            countryName,
            cityName
        );
    }

    async getSpecialFeeByPropertyType(propertyType: string) {
        return this.api.simpleGet(
            "settings/specials-fees/property-type",
            propertyType
        );
    }

    //post
    async saveAnnounce(announce: AnnounceInputs, idProperty: string) {
        const announceReq: AnnounceInputs = {
            ...announce,
            property: idProperty,
        };
        return this.api.post("announces/create", announceReq);
    }

    async savePropertyPhotosById(id: string, propertyPhoto: any) {
        return this.api.postFile("properties/uploadPhoto", id, propertyPhoto);
    }

    async saveProperty(property: PropertyInputs) {
        return this.api.post("properties/create", property);
    }

    async updatePropertyById(property: PropertyInputs) {
        if (property._id)
            return this.api.postById(
                "properties/update",
                property._id,
                property
            );
    }

    //delete
    async deleteAnnounce(id: string) {
        return this.api.simpleDeleteById(`announces/delete`, id);
    }

    async deletesAnnounce(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("announces/deleteMany", param);
    }

    async getReviewsByAnnounceID(id: string) {
        return this.api.simpleGet("reviews/byAnnounce", id);
    }

    async deleteProperty(id: string) {
        return this.api.simpleDeleteById(`properties/delete`, id);
    }

    async deleteProperties(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("properties/deleteMany", param);
    }

    async deletePropertyPhotoByName(idProperty: string, photoName: string) {
        return this.api.deletePropertyPhoto(
            "properties/deletePhoto",
            idProperty,
            photoName
        );
    }

    async deletePropertyPhotoById(idProperty: string, idPhoto: string) {
        return this.api.deletePropertyPhoto(
            "properties/deletePhoto/ById",
            idProperty,
            idPhoto
        );
    }

    async refreshPropertyPhotos(idProperty: string) {
        return this.api.simpleGet("properties/getPhoto/ById", idProperty);
    }

    async getAllSearchTypes() {
        return this.api.get("searchTypes");
    }

    // put
    async allowOrBlockAnnoncePublishing(
        announceId: string,
        newAnounceState: string
    ) {
        const params = {
            announceId,
            state: newAnounceState,
        };

        return this.api.put("announces-validations/publishing", params);
    }
}
