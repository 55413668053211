import {CurrencyConvert} from "./CurrencyConverterService";

// Create a single instance of CurrencyConvert for reuse
const converter = new CurrencyConvert();

export function ConvertPrice(base: string, to: string, amount: number) {
    //if (base.toUpperCase() === to.toUpperCase()) return Promise.resolve(amount);

    if (!base || !to) {
        return Promise.reject(new Error("Invalid currency codes."));
    }
    if (base.toUpperCase() === to.toUpperCase()) return Promise.resolve(amount);
    
    /*return converter.convert(base?.toLowerCase(), to?.toLowerCase(), amount)
        .then((value: any) => value)
        .catch(error => {
            console.log(error);
            return amount; // Return original amount on error
        });*/

    
        return converter.convert(base?.toLowerCase(), to?.toLowerCase(), amount)
        .then((value: any) => {
            if (!value || isNaN(value)) {
                throw new Error(`Conversion failed for ${base} to ${to}.`);
            }
            return value;
        })
        .catch(error => {
            console.error(`Conversion error: ${error.message || error}`);
            return amount; // Return original amount on error
        });
    
}

export function ConvertPriceWithFormat(base: string = "eur", to: string, amount: number) {
    if (base.toUpperCase() === to.toUpperCase()) return Promise.resolve(amount.toLocaleString('en-IN', { style: 'currency', currency: to }));

    return converter.convert(base?.toLowerCase(), to?.toLowerCase(), amount)
        .then((value: any) => new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: to,
            maximumSignificantDigits: 3
        }).format(value))
        .catch(error => {
            console.log(error);
            return amount.toLocaleString('en-IN', { style: 'currency', currency: base }); // Return formatted original on error
        });
}

// Simplified conversion function without currency symbol
export async function ConvertedDeviseWithoutMoneySign(from: string = "xaf", to: string, amount: number) {
    return await handleConversionWithoutSymbol(from, to, amount);
}

export async function ConvertedDeviseWithoutMoneySignPromise(from: string = "xaf", to: string, amount: number) {
    return handleConversionWithoutSymbol(from, to, amount);
}

async function handleConversionWithoutSymbol(from: string, to: string, amount: number) {
    try {
        if (from.toUpperCase() === to.toUpperCase()) return adjustPrecision(amount, to);
        const rate: any = await converter.convertDevise(from.toUpperCase(), to.toUpperCase());
        const convertedValue = (rate[to.toUpperCase()] * amount).toFixed(2);
        return adjustPrecision(Number(convertedValue), to);
    } catch (error) {
        console.log(error);
        return amount.toString();
    }
}

// Conversion with currency symbol
export async function ConvertedDeviseWithMoneySign(from: string = "xaf", to: string, amount: number) {
    try {
        if (from.toUpperCase() === to.toUpperCase()) return adjustPrecision(amount, to) + ' ' + to.toUpperCase();
        const rate: any = await converter.convertDevise(from.toUpperCase(), to.toUpperCase());
        const convertedValue = (rate[to.toUpperCase()] * amount).toFixed(2);
        return adjustPrecision(Number(convertedValue), to) + ' ' + to.toUpperCase();
    } catch (error) {
        console.log('CurrencyConvertFunction error', error);
        return amount.toLocaleString() + ' ' + from.toUpperCase();
    }
}

// Helper to adjust precision based on currency type
function adjustPrecision(value: number, currency: string) {
    return currency.toLowerCase() === 'xaf' ? Math.ceil(value).toString() : value.toFixed(2);
}
