import React from 'react'
import { UserPromotionService } from './services/UserPromotionService';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Moment } from 'moment';
import { FocusedInputShape } from 'react-dates';
import { AfriqDateRange } from '../../components/AfriqDateRange';
import { PropertyTextField } from '../../Properties/components/PropertyTextField';
import { IAnnounce, PromotionInputs } from '../../../utils/AnnounceInterfaces';
import { promotionInputsDefaultValue } from '../../../utils/defaultNullValues';
import { AfriqAnnouncesSelectList, ISelectProps } from '../../components/AfriqAnnouncesSelectList';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SessionHandler from '../../../https/SessionHandler';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/AppContext';
import { PropertyService } from '../../Properties/services/PropertyService';
import { CircularProgress } from '@material-ui/core';
import { getErrorLabel, PromotionErrorMessage } from '../../../utils/UserInterfaces';
import { Error } from '@material-ui/icons';
import { colors } from '../../../styles/defaultColors';
// import { useQuery } from '../../../utils/App';
import { PropertyTextFieldReadOnly } from '../../Properties/components/PropertyTextFieldReadOnly';
import { ListPromotions } from './components/ListPromotions';
import { ISnackbar } from '../../../utils/enum/snackBarType';
import { AfriqSnackbar } from '../../components/OverridingMaterial/AfriqSnackbar';
import { useParams } from 'react-router';
import moment from 'moment';
import Spin from '../../../images/Spin';
import { RouteAdminUser } from '../../Admin/constant/routesName';
import { EAnnounceStatus } from '../../../utils/enum';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        promotionContainer:{
            paddingBottom: '25%'
        },
        Button: {
            fontFamily: "Josefin Sans",
            color: "#890608",
            textTransform: "capitalize",
        },
        cancelButton: {
            textTransform: "capitalize",
            color: "#000000",
        },
        promotionTitle: {
            fontSize: 22,
            fontFamily: "Josefin Sans",
            color: "#890608",
            marginTop: 5,
            marginBottom: 25,
        },
        promotionDateRange:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            marginTop: 16, 
            marginBottom: 16,
            '& .DateRangePickerInput':{
                justifyContent: 'flex-start!important'
            },
            '& .DateInput':{
                width: '80px!important'
            }

        },
        enregistrer: {
            borderRadius: 100,
            textTransform: "none",
            fontSize: 14,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
            fontFamily: 'Josefin Sans',
            background: "#890608",
            color: "#ffffff",
            padding: "8px 14px",
            "&:hover":{
                background: "#B5551B"
            }
        },
        updateButton: {
            borderRadius: 100,
            textTransform: "none",
            fontSize: 14,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(2),
            textAlign: "center",
            fontFamily: 'Josefin Sans',
            background :"#AAAAAA",
            color: "#ffffff",
            padding: "8px 14px",
            "&:hover": {
                background: "#B5551B"
            },
        },
        errors: {
            color: colors.danger,
            background: colors.backgroundDanger,
            width: "100%",
            textAlign: "center",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            borderRadius: 5,
            "& p": {
                padding: 10,
            },
        },
        inpuText: {
            marginBottom: 14,
            marginTop: 6
        },
        inputLabel: {
            fontSize: 14,
            fontFamily: "Josefin Sans",
            color: "#000000",
        },
        containerLabel: {
            textAlign: "left",
        },
        promotionBtns: {
            display: "flex",
            maxWidth: 225,
            justifyContent: "space-between",
            marginBottom: 20,
            [theme.breakpoints.down("sm")]: {
                margin: "0 auto",
            },
        },
    })
);

const userPromotionService= new UserPromotionService();
const propertyService = new PropertyService();
export default function UserPromotion() {
    const classes= styles();
    const sessionHandler = React.useMemo(() => new SessionHandler(), []);
    const isConnected = sessionHandler.isConnected;
    const userContext = useContext(UserContext);
    const { user, setUser } = userContext;
    const params:{id:string} =useParams();
    // const query= useQuery()
    const history = useHistory();
    const [focusedInput, setFocusedInput] =
        React.useState<FocusedInputShape | null>(null);

    const [selectedAnnounce, setSelectedAnnounce]=React.useState<ISelectProps>({
        id: '',
        name: '',
        price: 0
    });
    const [announcesList, setAnnouncesList]=React.useState<ISelectProps[]>([]);
    const [promotion, setPromotion]= React.useState<PromotionInputs>(promotionInputsDefaultValue)
    const {t} = useTranslation();
    const [loadingListAnnounces, setLoadingListAnnounces]=React.useState<boolean>(true);

    let errors: string[] = [];
    const [errorMessages, setErrorMessages] = React.useState<string>("");
    const [snackValues, setSnackValues] = React.useState<ISnackbar>({
        type: "success",
        open: false,
        message: "",
    });
    const [updateTable, setUpdateTable]= React.useState<boolean>(false);
    const [loading, setLoading]= React.useState<boolean>(false);
    const [isUpdating, setIsUpdating]= React.useState<boolean>(false);
    const [updateAnnounceSelectList, setUpdateAnnounceSelectList]= React.useState<boolean>(false);

    React.useEffect(()=>{
        if (!user.email) {
            isConnected()
                .then((response: any) => {
                    if (response.success) {
                        setUser(response.data);
                    }
                })
                .catch((err) => {
                    history.push("/");
                });
        } else {
            propertyService.getListAnnounces({ owner: user._id /*, promotion: null*/, state: EAnnounceStatus.Published}).then(response=>{
                if(response.success){
                    setAnnouncesList(response.data.map((announce: IAnnounce, index:number) => {
                        return {
                                id: announce?._id || index.toString(),
                                name: announce?.property?.name,
                                price: announce?.price
                            }
                        })
                    )  
                    setLoadingListAnnounces(false)
                }
            }).catch(error=>{
                setLoadingListAnnounces(false)
                console.log('userPromotion component error', error)
            })            
        }
        
    },[user, history, isConnected, setUser])

    React.useEffect(()=>{
        // console.log("Params ID", params.id)
        if(params.id){
            setIsUpdating(true)
            userPromotionService.getPromotionById(params.id).then(response=>{
                if(response.success){
                    setPromotion({...response.data, startDate: moment(response.data.startDate), endDate: moment(response.data.endDate)});

                    propertyService.getAnnounceByIdAdm(response.data.announce._id).then(response=>{
                        if(response.success){
                            setSelectedAnnounce({
                                        id: response.data._id || "",
                                        name: response.data.property.name,
                                        price: response.data.price
                                    })  
                        }
                    })
                }
                
            }).catch(error=>{
                
            });
        }
        
    }, [params.id])

  React.useEffect(()=>{
      if(updateAnnounceSelectList){
        setLoadingListAnnounces(true);
        propertyService.getListAnnounces({ owner: user._id/*, promotion: null */, state: EAnnounceStatus.Published}).then(response=>{
            if(response.success){
                setAnnouncesList(response.data.map((announce: IAnnounce, index:number) => {
                    return {
                            id: announce?._id || index.toString(),
                            name: announce?.property?.name,
                            price: announce?.price
                        }
                    })
                );  
                
                setLoadingListAnnounces(false)
                setUpdateAnnounceSelectList(false);
            }
        }).catch(error=>{
            setLoadingListAnnounces(false)
            setUpdateAnnounceSelectList(false)
            console.log('userPromotion component error', error)
        })
      }
   
   },[updateAnnounceSelectList, user._id])
    
    const handleFocusChange = (arg: FocusedInputShape | null) => {
        setFocusedInput(arg);
    };
    

    const handlendDatesChange = ({startDate, endDate}: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => {        
        setPromotion((prev)=>{
            return {
                ...prev,
            startDate,
            endDate
            }
        })
    };

    const handleChange=(e:any)=>{
        setPromotion((prev)=>{
            return {
                ...prev,
            [e.target.name]: e.target.value
            }
        })
    }

    const handleSelectAnnounce=(v: ISelectProps)=>{
        setSelectedAnnounce(v);
        setPromotion((prev)=>{
            return {
                ...prev,
                announce: v.id,
                price:  parseFloat(Number((v.price - (promotion.discount/100) * v.price)).toFixed(2))
            }
        })
    }

    const handleChangeDiscount=(e:any)=>{
        if(selectedAnnounce.price > 0 && e.target.value <= 100) {
            setPromotion((prev)=>{
                return {
                    ...prev,
                    discount: parseFloat(Number(e.target.value).toFixed(2)),
                    price:  parseFloat(Number((selectedAnnounce.price - (parseFloat(e.target.value)/100) * selectedAnnounce.price)).toFixed(2))
                }
            })   
        }
    }

    const handleChangeDiscounPrice=(e:any)=>{
        if(selectedAnnounce.price > 0 && e.target.value <= selectedAnnounce.price){
            setPromotion((prev)=>{
                return {
                    ...prev,
                    discount:  parseFloat(Number((100- (parseFloat(e.target.value)/selectedAnnounce.price) * 100)).toFixed(2)) ,
                    price:  parseFloat(Number(e.target.value).toFixed(2))
                }
            })
        }
    }

    //Reset snackbar
    const handleCloseSnackbar = () => {
        setSnackValues({ ...snackValues, open: false })
    };


    const isFromValidate = (values: PromotionInputs): boolean => {
        if (values.title==='') {
            if (!errors.includes("EMPTY PROMOTION TITLE"))
                errors = [...errors, "EMPTY PROMOTION TITLE"];
        } else if (values.announce==='') {
            if (!errors.includes("NO ANNOUNCE SELECTED"))
                errors = [...errors, "NO ANNOUNCE SELECTED"];
        } else if (values.discount ===0 || values.price===0) {
            if (!errors.includes("EMPTY DISCOUNT"))
                errors = [...errors, "EMPTY DISCOUNT"];
        }else if (values.startDate===null || values.endDate===null){
            if (!errors.includes("EMPTY EXPIRATION DATE"))
                errors = [...errors, "EMPTY EXPIRATION DATE"];
        }
        return !Boolean(errors.length);
    };

    const showErrorsMessages = () => {
        let allErrorMessages = "";
        errors.forEach((error) => {
            if (error === "EMPTY PROMOTION TITLE") {
                allErrorMessages += "," + getErrorLabel(PromotionErrorMessage.EmptyPromotionTitle);
            } else if (error === "NO ANNOUNCE SELECTED") {
                allErrorMessages += "," + getErrorLabel(PromotionErrorMessage.NoAnnounceSelected);
            }  else if (error === "EMPTY DISCOUNT") {
                allErrorMessages += "," + getErrorLabel(PromotionErrorMessage.EmptyDiscount);
            } else if (error === "EMPTY EXPIRATION DATE") {
                allErrorMessages += "," + getErrorLabel(PromotionErrorMessage.EmptyExpirationDate);
            } else if (error==='SELECT OTHER DATE'){
                allErrorMessages += "," + getErrorLabel(PromotionErrorMessage.DateNotSelectable);
            }
        });
        setErrorMessages(
            allErrorMessages.substring(1, allErrorMessages.length)
        );
    };

    const handleCancel=()=>{
        setPromotion(promotionInputsDefaultValue);
        setSelectedAnnounce({
            id: '',
            name: '',
            price: 0
        });
        setErrorMessages('');
        errors=[];
        setUpdateAnnounceSelectList(true);
        setUpdateTable(true);
        setSnackValues({
            type: "info",
            open: true,
            message: t('snackbar.messages.modification-annule'),
        });
        setIsUpdating(false);
        setLoading(false);
        history.replace(RouteAdminUser.PROMOTION)
    }

    const handleSubmit=(e:React.SyntheticEvent)=>{
        e.preventDefault();
        if(isFromValidate(promotion)){
            setLoading(true)
            if(promotion._id && promotion._id!==''){
                userPromotionService.updatePromotionById(promotion).then(response=>{
                if(response.success){

                    setSnackValues({
                        ...snackValues,
                            open: true,
                            message: 'Enregistrer avec succès',
                        });
                        setPromotion(promotionInputsDefaultValue);
                        setSelectedAnnounce({
                            id: '',
                            name: '',
                            price: 0
                        });
                        setErrorMessages('');
                        errors=[];
                        setUpdateAnnounceSelectList(true);
                        setUpdateTable(true);
                    }
                    setLoading(false)
                }).catch(error=>{
                    setUpdateTable(false);
                    setLoading(false)
                    if(error.error==='SELECT OTHER DATE'){
                        if (!errors.includes("SELECT OTHER DATE"))
                        errors = [...errors, "SELECT OTHER DATE"];
                    }
                    showErrorsMessages()
                    console.log('userPromotion component error on save promotion', error)
                })
            }else{
                userPromotionService.savePromotion(promotion).then(response=>{
                if(response.success){
                    setSnackValues({
                        ...snackValues,
                            open: true,
                            message: 'Enregistrer avec succès',
                        });
                        setPromotion(promotionInputsDefaultValue)
                        setErrorMessages('');
                        errors=[];
                        setUpdateAnnounceSelectList(true);
                        setUpdateTable(true);
                        setSelectedAnnounce({
                            id: '',
                            name: '',
                            price: 0
                        });
                    }
                    setLoading(false)
                }).catch(error=>{
                    setUpdateTable(false);
                    setLoading(false)
                    setErrorMessages('');
                    if(error.error==='SELECT OTHER DATE'){
                        if (!errors.includes("SELECT OTHER DATE"))
                        errors = [...errors, "SELECT OTHER DATE"];
                    }
                    showErrorsMessages()
                    console.log('userPromotion component error on save promotion', error)
                })
                
            }
        }else{
            showErrorsMessages();
            return false;

        }
    }
    
    return (
        <div className={classes.promotionContainer}>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.promotionTitle}
                    >
                        {t("label.editer-un-promotion")}
                    </Typography>
                    <Typography variant='subtitle2'>{t('label.promotions-subtitle')}</Typography>
                </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid item md={6}>
                            <PropertyTextField
                                id="promotionTitle"
                                label={`${t("label.intitule-de-la-promotion")} * :`}
                                name="title"
                                value={promotion.title}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <Grid item md={6}>
                            {
                                loadingListAnnounces && (<CircularProgress size={32} style={{color: '#890608'}} />)
                            }
                            {
                                !loadingListAnnounces && <AfriqAnnouncesSelectList selectedAnnounce={selectedAnnounce} handleSelectAnnounce={handleSelectAnnounce} listAnnounces={announcesList} />
                            }
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <PropertyTextFieldReadOnly
                            id="actualPrice"
                            label={`${t("label.prix-actuel")} (${t('annonce.labels.byNight')})`}
                            name="ActualPrice"
                            value={selectedAnnounce.price.toString() || '0'} 
                        />
                    </Grid>
                    <Grid item md={2}>
                        <PropertyTextField
                            id="discountPercent"
                            label={`${t("label.reduction")} (%)`}
                            name="discount"
                            value={promotion.discount || 0}
                            handleChange={handleChangeDiscount}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <PropertyTextField
                            id="discountPrice"
                            label={`${t("label.apres-reduction")} (${t('annonce.labels.byNight')})`}
                            name="discount"
                            value={promotion.price || 0}
                            handleChange={handleChangeDiscounPrice}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Grid
                            item
                            lg={12}
                            sm={12}
                            xs={12}
                            className={classes.containerLabel}
                        >
                            <label className={classes.inputLabel}>
                                {t("label.date-d-echeance")}
                            </label>
                        </Grid>
                        <div className={classes.promotionDateRange}>
                            <Grid item xs={12} md={12} lg={12} justifyContent="flex-start">
                                <AfriqDateRange startDate={promotion.startDate} endDate={promotion.endDate} handleDateSelection={handlendDatesChange} handleFocusChange={handleFocusChange} focusedInput={focusedInput} />
                            </Grid>
                        </div>
                    </Grid>
                {errorMessages && (
                    <Grid item xs={12} md={12} lg={12}>
                        <div className={classes.errors}>
                            <Typography>
                                <Error />
                                <br />
                                <span>{errorMessages}</span>
                            </Typography>
                        </div>

                    </Grid>
                )}
                    <Grid item xs={12} md={12} lg={12}>
                    <div className={classes.promotionBtns}>
                        {
                            isUpdating &&
                            (<Button
                                className={classes.updateButton}
                                onClick={handleCancel}
                            >
                            {t('label.annuler')}
                        </Button>)

                        }
                        <Button
                            type={"submit"}
                            className={classes.enregistrer}
                            endIcon={
                                loading ? <Spin /> : null
                            }
                            disabled={loading}
                        >
                        {t('annonce.buttonSave')}
                        </Button>
                    </div>
                    </Grid>     
                <AfriqSnackbar
                    {...snackValues}
                    handleClose={handleCloseSnackbar}
                />
            </Grid>
            </form>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <ListPromotions updateTable={updateTable} setUpdateTable={setUpdateTable} setUpdateAnnounceSelectList={setUpdateAnnounceSelectList} />
                </Grid>
            </Grid>
        </div>
    )
}
