import Api from "../../../../https/Api";

export class CountryService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getAllDestination() {
        return this.api.get("destinations");
    }

    async getCitiesList() {
        return this.api.get("cities");
    }

    //get
    async getBasicCountryInfo() {
        return this.api.get("destinations/homepage");
    }

    async getCommissionFeeByCountryAndCityId(
        countryName: string,
        cityName: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city",
            countryName,
            cityName
        );
    }


    async getCommissionFeeByCountryIdAndCityId(
        countryId: string,
        cityId: string
    ) {
        return this.api.simpleGetWithTwoParams(
            "settings/commissions-fees-country-city-by-id",
            countryId,
            cityId
        );
    }



    async getSpecialFeeByPropertyType(propertyType: string) {
        return this.api.simpleGet(
            "settings/specials-fees/property-type",
            propertyType
        );
    }

    async getAllEquipments() {
        return this.api.get("equipments");
    }

    async getDestinationById(id: string) {
        return this.api.simpleGet("destinations/get", id);
    }

    //post

    async saveDestination(destination: any) {
        return this.api.post("destinations/create/", destination);
    }

    //delete
    async deleteDestination(id: string) {
        return this.api.simpleDeleteById(`destinations/delete`, id);
    }

    async deleteCategories(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("categories/deleteMany", param);
    }

    async deleteEquipments(ids: string[]) {
        const param = {
            ids: ids,
        };
        return this.api.deleteMany("equipments/deleteMany", param);
    }
    async saveDestinationPhotos(Photo: any) {
        return this.api.postFileupload("destinations/upload", Photo);
    }

    // Create City
    async createCity(cityData: any) {
        return this.api.post("cities/create", cityData);
    }

    // Get All Cities
    async getAllCities() {
        return this.api.get("cities");
    }

    // Get City by ID
    async getCityById(id: string) {
        return this.api.simpleGet("cities", id);
    }

    // Update City
    async updateCity(id: string, cityData: any) {
        return this.api.put(`cities/update/${id}`, cityData);
    }

    // Delete City
    async deleteCity(id: string) {
        return this.api.simpleDeleteById("cities/delete", id);
    }

    // Countries CRUD methods

    // Create Country
    async createCountry(countryData: any) {
        return this.api.post("countries/create", countryData);
    }

    // Get All Countries
    async getAllCountries() {
        return this.api.get("countrieslist");
    }

    // Get Country by ID
    /*async getCountryById(id: string) {
        return this.api.simpleGet("countries", id);
    } */

    async getCountryById(id: string) {
        const url = `countries/${id}`;
        //console.log("Requesting country details from:", url);
    
        try {
            const response = await this.api.simpleGet("countries", id);
            //console.log("Raw API response:", response);
    
            return response; // Ensure this is a JSON object
        } catch (error) {
            console.error("Error fetching country data:", error);
            throw error;
        }
    }
    

    // Update Country
    async updateCountry(id: string, countryData: any) {
        return this.api.put(`countries/update/${id}`, countryData);
    }

    // Delete Country
    async deleteCountry(id: string) {
        return this.api.simpleDeleteById("countries/delete", id);
    }

    // Get Country by ID
    async getCountryByName(name: string) {
        return this.api.simpleGet("countries/name", name);
    }

    // Get City by ID
    async getCityByName(name: string) {
        return this.api.simpleGet("cities/name", name);
    }

}
