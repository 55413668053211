import Api from './Api';
import { paypalConfig } from '../configs/Paypal';
import { orangeConfig } from '../configs/Orange';
class SessionHandler {


    saveUserInfo(userConnected) {
        localStorage.setItem('user', JSON.stringify(userConnected));
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem('user'));
    }
    
    removeUserInfo() {
        return localStorage.getItem('user');
    }

    isConnected() {
        var token = localStorage.getItem('access_token');
        if (token === null || token.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/getCurrentUser/").then((response) => {
            return Promise.resolve(response);
        }).catch((err) => {
                return Promise.reject(err);
        });
        return res;
    }

    isRefreshed() {
        var refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken === null || refreshToken.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/refresh-token").then((response) =>{
            localStorage.setItem('access_token', response.user.token)
            localStorage.setItem('refresh_token', response.user.refreshToken)
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
        return res;
    }
    /*saveUserInfo(userConnected) {
        localStorage.setItem('user', JSON.stringify(userConnected));
    }

    getUserInfo() {
        return JSON.parse(localStorage.getItem('user'));
    }
    
    removeUserInfo() {
        return localStorage.removeItem('user');
    }

    /*
    isConnected() {
        var token = localStorage.getItem('access_token'); 
        if (token === null || token.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/getCurrentUser/").then((response) => {
            return Promise.resolve(response);
        }).catch((err) => {
                return Promise.reject(err);
        });
        return res;
    } 

        isConnected() {
            let token = localStorage.getItem('access_token');
            console.log("isConnected: Retrieved access token:", token);
        
            // Check if getUserData is a function before calling it
            if (typeof this.getUserData !== 'function') {
                console.error("isConnected: getUserData is not a function or undefined.");
                return Promise.reject("Helper function getUserData is unavailable");
            }
        
            if (!token) {
                console.log("isConnected: No access token found, attempting to refresh...");
                // Try refreshing the token if access_token is missing
                return this.isRefreshed()
                    .then(() => {
                        token = localStorage.getItem('access_token');
                        console.log("isConnected: Token after refresh attempt:", token);
                        if (!token) {
                            console.error("isConnected: Refresh failed, still no access token");
                            return Promise.reject("Failed to refresh token");
                        }
                        return this.getUserData(token); // Call getUserData with refreshed token
                    })
                    .catch(err => {
                        console.error("isConnected: Error during refresh attempt:", err);
                        return Promise.reject(err);
                    });
            } else {
                console.log("isConnected: Access token found, fetching user data...");
                return this.getUserData(token); // Call getUserData with existing token
            }
        }
        
        

        getUserData(token) {
            console.log("getUserData: Fetching user data with token:", token);
            const api = new Api();
        
            return api.post("user/getCurrentUser/", {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log("getUserData: Received response:", response.status, response.statusText);
        
                if (!response.ok) {
                    console.error("getUserData: Request failed with status:", response.status);
                    return Promise.reject(`Failed to fetch user data: ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("getUserData: Data received:", data);
        
                if (data && data.data) {
                    console.log("getUserData: Saving user info to local storage.");
                    this.saveUserInfo(data.data); // Save user info if successful
                    return Promise.resolve(data);
                } else {
                    console.error("getUserData: User data missing in response");
                    return Promise.reject("User data missing in response");
                }
            })
            .catch(err => {
                console.error("getUserData: Error fetching user data:", err);
                return Promise.reject(`Error in getUserData: ${err.message}`);
            });
        }
        
                

/*
    isRefreshed() {
        var refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken === null || refreshToken.length === 0)
            return Promise.reject(false);
        let api = new Api();
        let res = api.post("users/refresh-token").then((response) =>{
            localStorage.setItem('access_token', response.user.token)
            localStorage.setItem('refresh_token', response.user.refreshToken)
            return Promise.resolve(response);
        }).catch((err) => {
            return Promise.reject(err);
        });
        return res;
    } *

        isRefreshed() {
            const refreshToken = localStorage.getItem('refresh_token');
            console.log("isRefreshed: Retrieved refresh token:", refreshToken);
        
            if (!refreshToken) {
                console.error("isRefreshed: No refresh token found");
                return Promise.reject("No refresh token found");
            }
        
            const api = new Api();
            console.log("isRefreshed: Attempting to refresh access token with refresh token...");
            return api.post("users/refresh-token", { refreshToken }) // Pass refreshToken as payload
                .then((response) => {
                    console.log("isRefreshed: Received response:", response);
                    if (response.success && response.token && response.refreshToken) {
                        console.log("isRefreshed: Refresh successful, updating tokens.");
                        localStorage.setItem('access_token', response.token);
                        localStorage.setItem('refresh_token', response.refreshToken);
                        if (response.user) {
                            console.log("isRefreshed: Saving user info after refresh.");
                            this.saveUserInfo(response.user);
                        }
                        return Promise.resolve(response);
                    } else {
                        console.error("isRefreshed: Token or refreshToken missing in response:", response);
                        return Promise.reject("Token or refreshToken missing in response");
                    }
                })
                .catch((err) => {
                    console.error("isRefreshed: Error during token refresh:", err);
                    return Promise.reject(err);
                });
        }*/
    
    //PAYPAL ------------------------------------------------------------
    refreshPaypalAccessToken() {
        let loginData={
            username:paypalConfig.cliendID,
            password: paypalConfig.secret
          }          
        let api = new Api();
        api.postPaypalRequestToken('https://api-m.sandbox.paypal.com/v1/oauth2/token', loginData).then(response=>{
            localStorage.setItem('access_paypal_token', response.access_token)
        })
        return this.getPaypalAccessToken();
    }
    savePaypalAccessToken(token) {
        localStorage.setItem('access_paypal_token', token);
    }

    getPaypalAccessToken() {
        return localStorage.getItem('access_paypal_token');
    }

    //--------------------------------------------
    //ORANGE ---------------------------------------
    refreshOrangeAccesToken(){
        let api = new Api();
        api.postOrangeRequestToken('https://api.orange.com/oauth/v2/token', orangeConfig.authorization_token).then(response=>{
            this.saveOrangeAccessToken( response.access_token);
        })
        return this.getOrangeAccessToken();
    }
    
    saveOrangeAccessToken(token) {
        localStorage.setItem('access_orange_token', token);
    }

    getOrangeAccessToken() {
        return localStorage.getItem('access_orange_token');
    }

    //---------------------------------------------------------------

    saveAccessToken(token) {
        localStorage.setItem('access_token', token);
    }

    getAccessToken() {
        return localStorage.getItem('access_token');
    }

    removeToken() {
        let keys = Object.keys(localStorage);
        keys.filter(k => k !== 'i18nextLng').forEach(key => {
            localStorage.removeItem(key);
        });
    }

    saveRefreshToken(refreshToken) {
        localStorage.setItem('refresh_token', refreshToken)
    }

    getRefreshToken() {
        return localStorage.getItem('refresh_token')
    }
}

export default SessionHandler;