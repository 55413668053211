/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from "lodash";
import React, { useState } from "react";
import { Box } from "@mui/system";
import {
    Card,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    CircularProgress,
    SelectChangeEvent,
    DialogContent,
    DialogActions,
    IconButton,
} from "@mui/material";
import TableToolBar from "./tableauToolbar";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import TableListHead from "./tableHeader";
import TableMoreMenu from "./tableMoreMenu";
import SearchNotFound from "./searchNotFound";
import { useTranslation } from "react-i18next";
import { getHeaderLabel } from "../../../../utils/App";
import PnbAlertDialog from "../../userServices/AlertDlg";
import {
    // createBookingRefund,
    createBookingRefundCredit,
    // createBookingRefundDohone,
    deleteResevationByIds,
    getAllHostReservation,
    getPaymentMethodFees,
    getTransactionDetailByReservationId,
    // saveTransaction,
    updateReservationState,
} from "../../../../Services/reservationServices";
import { UserContext } from "../../../../contexts/AppContext";
import { useContext } from "react";
import {
    createData,
    DataReservation,
    IReservationReq,
} from "../../../../Models/reservationModels";
import { makeStyles } from "@material-ui/core";
import { EStatus, getStatusLabel } from "../../../../utils/enum";
import { CurrencyContext } from "../../../../contexts/AppContext";
import moment from "moment";
import { ConvertedDeviseWithoutMoneySign } from "../../../../utils/CurrencyConvertFunction";
import {
    DohonePayoutMode,
    IRefund,
    IRefundDohone,
    ITransactionReq,
} from "../../../../utils/PaymentInterfaces";
import {
    EPaymentMethod,
    EPaymentMethodCategory,
    // ETransactionType,
} from "../../../../utils/enum/PaymentMethod";
import Spin from "../../../../images/Spin";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order: string, orderBy: string) {
    return order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
    array: any[],
    comparator: (a: any, b: any) => number,
    query: string,
    fkey: string
) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        if (fkey === "travellerNumber") {
            return array.filter(
                (el) => Number(el.travellerNumber) === Number(query)
            );
        } else if (fkey === "requestAge") {
            return array.filter(
                (el) => Number(el.requestAge) === Number(query)
            );
        } else if (fkey === "bookingDate") {
            return array.filter(
                (el) =>
                    moment(el.bookingDate)
                        .format("DD/MM/YYYY hh:mmA")
                        .indexOf(query) !== -1
            );
        } else if (fkey === "dateCheckIn") {
            return array.filter(
                (el) => dateToString(el.dateCheckIn).indexOf(query) !== -1
            );
        } else if (fkey === "dateCheckOut") {
            return array.filter(
                (el) => dateToString(el.dateCheckOut).indexOf(query) !== -1
            );
        } else {
            return _.filter(
                array,
                (_reserv) =>
                    (_.get(_reserv, fkey) ?? "")
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
            );
        }
    }
    return stabilizedThis.map((el) => el[0]);
}

const dateToString = (nDate: Date) => {
    const cDate = new Date(nDate);
    const day = cDate.getDate();
    const mouth = cDate.getMonth() + 1;
    const year = cDate.getFullYear();

    return `${day < 10 ? 0 : ""}${day}-${mouth < 10 ? 0 : ""}${mouth}-${year}`;
};

// ----------------------------------------------------------------------

export const BookingList = () => {
    const { t, i18n } = useTranslation();
    i18n.on("languageChanged", function (lng) {
        moment.locale(lng);
    });
    const userContext = useContext(UserContext);
    const { user } = userContext;
    const TABLE_HEAD = [
        {
            id: "property",
            label: getHeaderLabel("property"),
            alignRight: false,
        },
        {
            id: "travellerNumber",
            label: getHeaderLabel("travellerNumber"),
            alignRight: false,
        },
        {
            id: "_id",
            label: getHeaderLabel("idReservation"),
            alignRight: false,
        },
        {
            id: "bookingDate",
            label: getHeaderLabel("bookingDate"),
            alignRight: false,
        },
        // { id: 'announce.description', label: 'Description', alignRight: false },
        {
            id: "dateCheckIn",
            label: getHeaderLabel("dateCheckIn"),
            alignRight: false,
        },
        {
            id: "dateCheckOut",
            label: getHeaderLabel("dateCheckOut"),
            alignRight: false,
        },
        {
            id: "requestAge",
            label: getHeaderLabel("duration"),
            alignRight: true,
        },
        { id: "validated", label: "Validation", alignRight: false },
        { id: "status", label: "Status", alignRight: true },
        { id: "" },
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("updatedAt");
    const [filterName, setFilterName] = useState("");
    const [fcolumn, setFColumn] = useState(TABLE_HEAD[0].id);
    const [selected, setSelected] = useState<string[]>([]);
    const [actjionIds, setActionIds] = useState<string[]>([]);
    const [delMessage, setDelMessage] = useState("");
    const [openDel, setOpenDel] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [reservationList, setReservationList] = useState<DataReservation[]>(
        []
    );
    const [gettingList, setGettingList] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogRejet, setOpenDialogRejet] = useState(false);
    const [select, setSelect] = useState<string>("");
    const [loadingButtons, setLoadingButtons] = useState<boolean>(false);
    const currencyContext = useContext(CurrencyContext);
    const { currency } = currencyContext;

    const handleFilterByName = (event: any) => {
        setFilterName(event.target.value);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setFColumn(event.target.value);
    };

    // const isBookingNotCancelable = (dateCheckOut: any): boolean => {
    //     return moment(new Date()) > moment(dateCheckOut);
    // };

    const handleRequestSort = (_event: any, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = reservationList.map((n: any) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleDeleteItemValidate = () => {
        setIsDeleting(true);
        deleteResevationByIds(actjionIds)
            .then((resp) => {
                if (resp && resp.success) {
                    setSelected([]);
                    setActionIds([]);
                    setReservationList(
                        reservationList.filter(
                            (res) =>
                                actjionIds.findIndex((re) => re === res._id) ===
                                -1
                        )
                    );
                }
                setIsDeleting(false);
                setOpenDel(false);
            })
            .catch((err) => {
                console.log(err);
                setIsDeleting(false);
                setOpenDel(false);
            });
    };

    const handleChangePage = (_event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        setGettingList(true);
        getAllHostReservation(user._id)
            .then((resp) => {
                if (resp)
                    setReservationList(
                        resp.map((reservation: IReservationReq) => {
                            return createData(
                                reservation._id ?? "",
                                reservation.announce?._id ?? "",
                                reservation.announce?.property?.name ?? "",
                                Number(
                                    reservation.travellerAdultTotal +
                                        reservation.travellerChildTotal
                                ),
                                reservation.createdAt,
                                reservation.dateCheckIn,
                                reservation.dateCheckOut,
                                reservation.status[
                                    reservation.status.length - 1
                                ].status === EStatus.OPEN &&
                                    reservation.status.length <= 1
                                    ? moment(new Date()).diff(
                                          reservation.createdAt,
                                          "hour"
                                      )
                                    : moment(reservation.status[1].date).diff(
                                          reservation.createdAt,
                                          "hour"
                                      ),
                                reservation.status[
                                    reservation.status.length - 1
                                ].status,
                                reservation.updatedAt,
                                reservation.announce?.property?.address
                                    ?.country?.name,
                                reservation.announce?.property?.address?.city?.name,
                                reservation.services,
                                reservation?.isPaymentPaused ?? false,
                                reservation?.announce?.remboursable ??
                                    "remboursable",
                                reservation?.incrimentationJour ?? 0
                            );
                        })
                    );
                setGettingList(false);
            })
            .catch((err) => {
                console.log(err);
                setGettingList(false);
            });
    }, []);

    const emptyRows =
        page > 0
            ? Math.max(0, (1 + page) * rowsPerPage - reservationList.length)
            : 0;
    const filteredReservation = applySortFilter(
        reservationList,
        getComparator(order, orderBy),
        filterName,
        fcolumn
    );
    const isReservNotFound = filteredReservation.length === 0;
    const classes = useStyle();

    const refreshReservationList = () => {
        setGettingList(true);
        getAllHostReservation(user._id)
            .then((resp) => {
                if (resp)
                    setReservationList(
                        resp.map((reservation: IReservationReq) => {
                            return createData(
                                reservation._id ?? "",
                                reservation.announce?._id ?? "",
                                reservation.announce?.property?.name ?? "",
                                Number(
                                    reservation.travellerAdultTotal +
                                        reservation.travellerChildTotal
                                ),
                                reservation.createdAt,
                                reservation.dateCheckIn,
                                reservation.dateCheckOut,
                                reservation.status[
                                    reservation.status.length - 1
                                ].status === EStatus.OPEN &&
                                    reservation.status.length <= 1
                                    ? moment(new Date()).diff(
                                          reservation.createdAt,
                                          "hour"
                                      )
                                    : moment(reservation.status[1].date).diff(
                                          reservation.createdAt,
                                          "hour"
                                      ),
                                reservation.status[
                                    reservation.status.length - 1
                                ].status,
                                reservation.updatedAt,
                                reservation.announce?.property?.address
                                    ?.country?.name,
                                reservation.announce?.property?.address?.city?.name,
                                reservation.services,
                                reservation?.isPaymentPaused ?? false,
                                reservation?.announce?.remboursable ??
                                    "remboursable",
                                reservation?.incrimentationJour ?? 0
                            );
                        })
                    );
                setGettingList(false);
            })
            .catch((err) => {
                console.log(err);
                setGettingList(false);
            });
    };

    const HandleClickDialog = (id: string) => {
        setOpenDialog(true);
        setSelect(id);
    };
    const handleClose = () => {
        setOpenDialog(false);
        setSelect("");
    };
    const HandleClickDialogRejet = (id: string) => {
        setOpenDialogRejet(true);
        setSelect(id);
    };
    const handleCloseRejet = () => {
        setOpenDialogRejet(false);
        setSelect("");
    };
    const handleValidate = () => {
        setLoadingButtons(true);
        updateReservationState(select, {
            status: String(EStatus.APPROVED),
        })
            .then(() => {
                refreshReservationList();
                handleClose();
            })
            .finally(() => setLoadingButtons(false));
    };

    const handleReject = () => {
        setLoadingButtons(true);
        let _travellerValue: number = 0;
        let _totalBookingPrice: number = 0;
        let _travellerReversalFees: number = 0;
        let _refundTraveller: IRefund;
        let _refundTravellerDohone: IRefundDohone;
        
        getTransactionDetailByReservationId(select)
            .then((transaction: ITransactionReq) => {
                _totalBookingPrice = transaction.amount ?? 0;
    
                getPaymentMethodFees(transaction.method).then((response) => {
                    const percentage = response?.percentage ?? 0; // Ensure a fallback value for `percentage`
                    _travellerReversalFees = (_totalBookingPrice * percentage) / 100;
                    _travellerValue = _totalBookingPrice - _travellerReversalFees;
    
                    if (transaction.reservation && transaction.reservation !== null) {
                        ConvertedDeviseWithoutMoneySign(
                            transaction.reservation.announce.devise || "EUR",
                            currency,
                            Number(_travellerValue.toFixed(2))
                        ).then((value: any): any => {
                            if (transaction.method === EPaymentMethod.Paypal) {
                                _refundTraveller = {
                                    ..._refundTraveller,
                                    amount: {
                                        value: value,
                                        currency: currency,
                                    },
                                    reservation:
                                        transaction.reservation !== null
                                            ? transaction.reservation._id ?? ""
                                            : "",
                                    recepient_type: "EMAIL",
                                    receiver: transaction.paymentEmail ?? "",
                                };
                                createBookingRefundCredit({
                                    amount: _refundTraveller.amount,
                                    reservation: _refundTraveller.reservation,
                                    method: EPaymentMethodCategory.Paypal,
                                    isCancellation: false,
                                }).then(() => {
                                    updateReservationState(select, {
                                        status: String(EStatus.REJECTED),
                                    }).then(() => {
                                        refreshReservationList();
                                        handleCloseRejet();
                                    });
                                });
                            } else {
                                _refundTravellerDohone = {
                                    ..._refundTravellerDohone,
                                    destination:
                                        transaction.reservation !== null
                                            ? (
                                                  transaction.reservation
                                                      .announce?.codePhone +
                                                  transaction.reservation
                                                      .traveller.receptions
                                                      .mobileMoney[0]
                                                      ?.mobile_number
                                              ).substring(1)
                                            : "",
                                    mode:
                                        transaction.reservation !== null
                                            ? transaction.reservation.traveller
                                                  .receptions.mobileMoney[0]
                                                  .mobile_operator ===
                                              EPaymentMethod.Orange
                                                ? DohonePayoutMode.orangeMoney
                                                : transaction.reservation
                                                      .traveller.receptions
                                                      .mobileMoney[0]
                                                      ?.mobile_operator ===
                                                  EPaymentMethod.MTN
                                                ? DohonePayoutMode.mtnMobileMoney
                                                : DohonePayoutMode.expressUnion
                                            : DohonePayoutMode.orangeMoney,
                                    amount: value,
                                    devise: currency,
                                    nameDest:
                                        transaction.reservation !== null
                                            ? transaction.reservation.traveller
                                                  .firstName
                                            : "",
                                    ville:
                                        transaction.reservation !== null
                                            ? transaction.reservation.announce
                                                  .property.address.city?.name
                                            : "",
                                    pays:
                                        transaction.reservation !== null
                                            ? transaction.reservation.announce
                                                  .property.address.country?.name
                                            : "",
                                    rUserId:
                                        transaction.reservation !== null
                                            ? transaction.reservation.traveller
                                                  ._id
                                            : "",
                                    reservation:
                                        transaction.reservation !== null
                                            ? transaction.reservation._id ?? ""
                                            : "",
                                };
                                // console.log("travellerDohone", _refundTravellerDohone)
    
                                createBookingRefundCredit({
                                    amount: {
                                        value: _refundTravellerDohone.amount,
                                        currency: _refundTravellerDohone.devise,
                                    },
                                    reservation: 
                                        _refundTravellerDohone.reservation,
                                    method: EPaymentMethodCategory.MobileMoney,
                                    isCancellation: false,
                                }).then(() => {
                                    updateReservationState(select, {
                                        status: String(EStatus.REJECTED),
                                    }).then(() => {
                                        refreshReservationList();
                                        handleCloseRejet();
                                    });
                                });
                            }
                        });
                    }
                });
            })
            .finally(() => setLoadingButtons(false))
            .catch((error) => {
                console.log(error);
                refreshReservationList();
                handleCloseRejet();
            });
    };

    return (
        <Box flex={1}>
            <Card>
                <TableToolBar
                    onDeletSelected={() => {
                        setActionIds(selected);
                        setDelMessage(
                            t("label.supprimer-les-elements-selectionnes")
                        );
                        setOpenDel(true);
                    }}
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    currentCol={fcolumn}
                    onColChange={handleChange}
                    colLists={TABLE_HEAD.filter(
                        (el) =>
                            el.id !== "status" &&
                            el.id !== "validated" &&
                            el.id !== ""
                    )}
                />
                <TableContainer
                    sx={{ minWidth: 800, padding: 4 }}
                    className={classes.tableau}
                >
                    <Table>
                        <TableListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={reservationList.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        {!gettingList && (
                            <TableBody>
                                {filteredReservation
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => {
                                        const {
                                            _id,
                                            announceId,
                                            property,
                                            travellerNumber,
                                            bookingDate,
                                            dateCheckOut,
                                            dateCheckIn,
                                            requestAge,
                                            status,
                                        } = row;
                                        const isItemSelected =
                                            selected.indexOf(_id) !== -1;
                                        return (
                                            <TableRow
                                                hover
                                                key={_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Typography variant="subtitle2">
                                                            {property}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {Number(travellerNumber)}{" "}
                                                    {t(
                                                        "listTable.labels.personnes"
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {_id}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {moment(bookingDate).format(
                                                        "DD/MM/YYYY hh:mmA"
                                                    )}
                                                </TableCell>

                                                <TableCell align="right">
                                                    {" "}
                                                    {dateToString(
                                                        dateCheckIn
                                                    )}{" "}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {dateToString(dateCheckOut)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {requestAge}h{" "}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                HandleClickDialog(
                                                                    _id
                                                                );
                                                            }}
                                                            variant="contained"
                                                            color="success"
                                                            disabled={
                                                                status !==
                                                                EStatus.OPEN
                                                            }
                                                        >
                                                            {t(
                                                                "reservation.button.valider"
                                                            )}
                                                        </Button>
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                                HandleClickDialogRejet(
                                                                    _id
                                                                )
                                                            }
                                                            color="error"
                                                            disabled={
                                                                status !==
                                                                EStatus.OPEN
                                                            }
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            {t(
                                                                "reservation.button.rejeter"
                                                            )}
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {getStatusLabel(status)}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <TableMoreMenu
                                                        onEdit={() => {
                                                            /** */
                                                        }}
                                                        onDelete={() => {
                                                            setActionIds([_id]);
                                                            setDelMessage(
                                                                `${t(
                                                                    "label.supprimer"
                                                                )} ${property}?`
                                                            );
                                                            setOpenDel(true);
                                                        }}
                                                        announceId={announceId}
                                                        reservationId={_id}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && !gettingList && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                        {isReservNotFound && !gettingList && (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={7}
                                        sx={{ py: 3 }}
                                    >
                                        <SearchNotFound
                                            sx={{ py: 3 }}
                                            searchQuery={filterName}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                        {gettingList && (
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        align="center"
                                        colSpan={7}
                                        sx={{ py: 3 }}
                                    >
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={reservationList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("listTable.labels.lignesParPage")}
                />
            </Card>

            <PnbAlertDialog
                title={t("reservation.dialog.supprimer")}
                open={openDel}
                setOpen={setOpenDel}
                onValidate={handleDeleteItemValidate}
                warning
                loading={isDeleting}
            >
                <Typography>{delMessage}</Typography>
            </PnbAlertDialog>

            {/* validation */}
            <Dialog open={openDialog} maxWidth="sm">
                <DialogTitle className={classes.titleDialog}>
                    <span className={classes.title}>
                        {t("reservation.dialog.valider.title")}
                    </span>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.contentDialog}>
                    {t("reservation.dialog.valider.description")}
                </DialogContent>
                <DialogActions className={classes.actionsDialog}>
                    <Button
                        variant="contained"
                        className={classes.annuler}
                        style={{ textTransform: "none" }}
                        onClick={handleClose}
                    >
                        {t("reservation.button.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.valider}
                        style={{ textTransform: "none" }}
                        onClick={handleValidate}
                        disabled={loadingButtons}
                    >
                        {t("reservation.button.valider")}
                        {loadingButtons && <Spin stroke="#FFF" />}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Rejet */}
            <Dialog open={openDialogRejet} maxWidth="sm">
                <DialogTitle className={classes.titleDialog}>
                    <span className={classes.title}>
                        {t("reservation.dialog.rejeter.title")}
                    </span>
                    <IconButton aria-label="close" onClick={handleCloseRejet}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.contentDialog}>
                    {t("reservation.dialog.rejeter.description")}
                </DialogContent>
                <DialogActions className={classes.actionsDialog}>
                    <Button
                        variant="contained"
                        className={classes.annuler}
                        style={{ textTransform: "none" }}
                        onClick={handleCloseRejet}
                    >
                        {t("reservation.button.cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        className={classes.rejeter}
                        style={{ textTransform: "none" }}
                        onClick={handleReject}
                        disabled={loadingButtons}
                    >
                        {t("reservation.button.rejeter")}
                        {loadingButtons && <Spin stroke="#d32f2f" />}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
/** STYLES */
const useStyle = makeStyles((theme) => ({
    tableau: {
        [theme.breakpoints.down("xs")]: {
            minWidth: "auto!important",
        },
    },
    head: {
        [theme.breakpoints.down("xs")]: {
            whiteSpace: "nowrap",
        },
    },
    title: {
        color: "#4D4F5C",
        fontSize: 18,
        fontWeight: 500,
        fontFamily:
            'Josefin Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    titleDialog: {
        padding: "5px 10px!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    contentDialog: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        paddingTop: "20px !important",
    },
    annuler: {
        borderRadius: "20px !important",
        textTransform: "capitalize",
        background: "#2F45B51A!important",
        color: "#000 !important",

        fontFamily:
            'Josefin Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important',
        boxShadow: "none!important",
    },
    valider: {
        borderRadius: "20px !important",
        background: "#2e7d32!important",
        color: "white !important",
        fontFamily:
            'Josefin Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important',
        boxShadow: "none!important",
    },
    rejeter: {
        borderRadius: "20px !important",
        fontFamily:
            'Josefin Sans,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important',
        boxShadow: "none!important",
        border: "1px solid rgba(211, 47, 47, 0.5) !important",
        color: "#d32f2f !important",
        background: "transparent !important",
    },
    actionsDialog: {
        padding: "10px !important",
    },
}));
