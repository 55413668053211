import Api from "../../../https/Api";

export class HomeService {
    private api: Api;
    constructor() {
        this.api = new Api();
    }

    //get
    async getListAnnouncesPerPage(page: number = 1, limit: number = 10) {
        return this.api.get(`announces_sliide/${page}/${limit}`);
    }
    async getListAnnounces() {
        //console.log("Fetching list of announcements from 'announces' endpoint...");
        return this.api.get(`announces`);
    }


    async getAnnounceSummary() {
        //console.log("Fetching list of announcements from 'announces' endpoint...");
        return this.api.get(`announces/summary`);
    }


    async getPropertyTypeSummary(searchType: string) {
        // Dynamically include `searchType` in the URL
        return this.api.get(`properties/summary/${searchType}`);
    }
    


    async getListCities() {
        return this.api.get(`cities`);
    }



    async getListAnnouncesAc() {
        return this.api.get(`announceAccueil`);
    }
     // Fetch announcements filtered by searchType
     async getListAnnouncesBySearchType(searchType: string, page: number = 1, limit: number = 10) {
        return this.api.get(`announces/searchType/${searchType}/${page}/${limit}`);
    }



    // Get listings with the highest review notes
    async getListingsWithHighestReviews(categoryId: string) {
        return this.api.get(`listings/highest-reviews?categoryId=${categoryId}`);
    }

    // Get listing types for a specific category
    async getListingTypesByCategory(categoryId: string) {
        return this.api.get(`listing-types?categoryId=${categoryId}`);
    }

    // Get popular amenities for a specific category
    async getPopularAmenities(categoryId: string) {
        return this.api.get(`amenities/popular?categoryId=${categoryId}`);
    }

    // Get additional listings for a category
    async getMoreListings(categoryId: string) {
        return this.api.get(`listings/more?categoryId=${categoryId}`);
    }

    // Get related destinations based on type and slug
    async getOtherDestinations(type: string, slug: string) {
        return this.api.get(`destinations/other?type=${type}&slug=${slug}`);
    }
    
}
